
























































































































































































































import { defineComponent, ref, reactive, computed, Ref } from '@vue/composition-api';
import { useProducts } from '@/admin/product';
import { useNotification } from '@/composition/notification';
import { savePermission, getPermissions, Permission } from '@/admin/permission';
import { useConfirm } from '@/composition/confirm';
import core from '@/admin/core';
import FcAuthority from '@/components/FcAuthority.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

interface Authority {
  planIds: string[];
  seasonIds: number[];
  productIds: string[];
  giftIds: string[];
  acceptAllUsers: boolean;
  acceptAllMembers: boolean;
  deny: boolean;
}

export default defineComponent({
  name: 'Products',
  components: {
    FcAuthority,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('products'));
    const isShippingAddress: boolean = process.env.VUE_APP_IS_SHIPPING === 'true';

    const { products } = useProducts();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const deleteProduct = async (productId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      try {
        await products.deleteProduct(productId);
        products.items = products.items.filter((item) => item.productId !== productId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
    };
    // 権限作成
    const isLoading = ref(true);
    const permissionItems: Ref<Permission[]> = ref([]);
    getPermissions()
      .then((permissions) => {
        permissionItems.value = permissions;
        isLoading.value = false;
      })
      .catch((error) => {
        notification.error(error);
        isLoading.value = false;
      });
    const active = ref(false);
    const isSaving = ref(false);
    const permissionPath = ref('');
    const permissionAuthority: Authority = reactive({
      planIds: [],
      seasonIds: [],
      productIds: [],
      giftIds: [],
      acceptAllUsers: false,
      acceptAllMembers: false,
      deny: false,
    });
    const cancel = () => {
      active.value = false;
      permissionAuthority.planIds = [];
      permissionAuthority.seasonIds = [];
      permissionAuthority.productIds = [];
      permissionAuthority.giftIds = [];
      permissionAuthority.acceptAllUsers = false;
      permissionAuthority.acceptAllMembers = false;
      permissionAuthority.deny = false;
    };
    const setPermission = (productId: string) => {
      active.value = true;
      permissionPath.value = '';
      permissionAuthority.productIds = [productId];
    };
    const save = async () => {
      permissionPath.value = permissionPath.value.trim();
      if (!permissionPath.value) {
        // 入力項目のバリデート
        notification.error('パスとタイプは必須です');
        return;
      } else if (permissionPath.value.match(/^\/#\/.*$/)) {
        // パス指定のバリデート
        notification.error('"/#"を含めずに記載してください');
        return;
      } else if (
        !permissionPath.value.match(/.*(\/|\.(json|png|jpeg|jpg|html|mp4|pdf))$/) &&
        !permissionPath.value.match(/^\/(gallery|movie)\/[a-zA-Z0-9]+$/)
      ) {
        // パス指定のバリデート
        notification.error('gallery, movie以外は"/"か".html"で終わるように指定してください');
        return;
      } else {
        // 新規作成で重複するパスのバリデート
        let duplicatedPath = '';
        permissionItems.value.some((item) => {
          const isDuplicated =
            (item.type === 'path' && permissionPath.value.includes(item.path)) ||
            item.path.includes(permissionPath.value);
          if (isDuplicated) duplicatedPath = item.path;
          return isDuplicated;
        });
        if (duplicatedPath) {
          notification.error(`${duplicatedPath}と権限が重複しています。`);
          return;
        }
      }
      const props = {
        type: 'path',
        path: permissionPath.value,
        ...permissionAuthority,
      };
      const method = 'POST';
      isSaving.value = true;
      try {
        await savePermission(props, method);
        notification.notify('保存しました');
        isLoading.value = true;
        permissionItems.value = await getPermissions();
        isLoading.value = false;
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
      cancel();
    };
    const headers = [
      { text: 'ステータス', value: 'isArchive' },
      { text: '商品タイプ', value: 'productType' },
      { text: '作成日', value: 'createDate.date' },
      { text: 'ID/タイトル', value: 'title', width: '400px' },
      { text: '価格', value: 'price' },
      { text: '設定在庫数', value: 'stockSettings.maxSalesQuantity' },
      { text: '合計注文数', value: 'stockSettings.salesQuantity' },
      { text: 'お届けまでの目安', value: 'deliveryMonths' },
      { text: '閲覧期間', value: 'accessDate', sortable: false },
      { text: '購入期間', value: 'purchaseDate', sortable: false },
      { text: '', value: 'actions', width: '320px', sortable: false, align: 'center' },
      { text: '閲覧権限URL', value: 'permissionPath', sortable: false },
    ];
    const hostingUrl = core.hostingUrl;

    // タイプで絞り込み
    const type = ref('');
    const types = [
      { text: '閲覧開始前', value: 'before-access' },
      { text: '閲覧期間中', value: 'access' },
      { text: '閲覧期間終了', value: 'end-access' },
      { text: '購入開始前', value: 'before-purchase' },
      { text: '購入期間中', value: 'purchase' },
      { text: '購入期間終了', value: 'end-purchase' },
    ];

    // フリーワード検索
    const searchWord = ref('');
    const isDisplayItem = ref(false);
    // 表示ユーザー一覧
    const displayProducts = computed(() => {
      const now = Date.now();
      const filterProduct = products.items
        // タイプによる絞り込み
        .filter((item) => {
          if (!type.value) return true;
          else if (type.value === 'access' && item.accessStartDate.value)
            return item.accessEndDate?.value
              ? item.accessStartDate.value < now && now < item.accessEndDate.value
              : item.accessStartDate.value < now;
          else if (type.value === 'purchase' && item.purchaseStartDate.value)
            return item.purchaseEndDate?.value
              ? item.purchaseStartDate.value < now && now < item.purchaseEndDate.value
              : item.purchaseStartDate.value < now;
          else if (type.value === 'before-access' && item.accessStartDate.value)
            return item.accessStartDate.value > now;
          else if (type.value === 'before-purchase' && item.purchaseStartDate.value)
            return item.purchaseStartDate.value > now;
          else if (type.value === 'end-access') return item.accessEndDate?.value && now > item.accessEndDate.value;
          else if (type.value === 'end-purchase')
            return item.purchaseEndDate?.value && now > item.purchaseEndDate.value;
          else return true;
        })
        // 削除スイッチによる絞り込み
        .filter((item) => (!isDisplayItem.value ? !item.isArchive : item))
        // フリーワードによる絞り込み
        .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value))
        .map((item) => {
          const permissionPath = permissionItems.value
            .filter((permission) => permission.productIds?.includes(item.productId))
            .map((permission) => permission.path)
            .join('\n');
          return {
            ...item,
            permissionPath,
          };
        });
      return filterProduct;
    });

    // テーブル高さ
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    const init = () => {
      if (isShippingAddress) {
        headers.splice(8, 0, { text: 'お届け先情報', value: 'isRequiredShippingAddress' });
      }
    };

    init();

    return {
      pageTitle: '個別課金',
      myRoleSettings,
      isShippingAddress,
      headers,
      hostingUrl,
      products,
      searchWord,
      displayProducts,
      isDisplayItem,
      type,
      types,
      deleteProduct,
      setPermission,
      save,
      cancel,
      active,
      permissionPath,
      permissionAuthority,
      isSaving,
      isLoading,
      tableHeight,
    };
  },
});
