import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';

export interface Permission {
  type: string;
  path: string;
  planIds?: string[];
  seasonIds?: number[];
  productIds?: string[];
  giftIds?: string[];
  acceptAllUsers?: boolean;
  acceptAllMembers?: boolean;
  deny?: boolean;
}
export const getPermissions = async () => {
  if (myAttributes.myRequestPermissions?.permissions) {
    const result = await core.httpClient.get('/admin/public/permissions');
    return result.data as Permission[];
  } else return [] as Permission[];
};
export const savePermission = async (data: Permission, method: 'PUT' | 'POST') => {
  await core.httpClient.request({ method, url: '/admin/public/permissions', data });
};
export const deletePermission = async (type: string, path: string) => {
  await core.httpClient.delete(`/admin/public/permissions?path=${encodeURIComponent(path)}&type=${type}`);
};
